export const COLORS = {
  AQUA: "#007D9C",
  CYAN: "#00CFCF",
  GREY: "#74728A",
  GREY_LIGHT: "#d5d4e3",
  PRIMARY_PINK: "#E440F2",
  PRIMARY_PURPLE: "#A440F2",
  PRIMARY_GREEN: "#50D313",
  GREEN_LIGHT: "#6EEFD2",
  PRIMARY_ORANGE: "#F28040",
  ORANGE_DARK: "#BD4807",
  PRIMARY_BLUE: "#0688FF",
  BLUE_LIGHT: "#92BBE1",
  BLUE_DARK: "#004B90",
  WHITE: "#FFFFFF",
  GREY_DARK: "#494866",
  DARK_CLEAN: "#F6F6F7",
  BLACK: "#212138",
  RED: "#BC1C00",
  RED_DARK: "#812312",
  YELLOW: "#D5AB00",
  YELLOW_DARK: "#896E00",
  BACKGROUND_BOXES: "#080509",
  BACKGROUND_FEATURES: "#111131",
  SHADOW_PINK_NEON: "rgba(223, 33, 254, 0.9)",
  SHADOW_BLUE_NEON: "#A2D2FE",
  SHADOW_BLUE_NEON_SECOND: "rgba(39, 225, 250, 0.3)",
  SHADOW_GREEN_NEON: "rgba(63, 217, 180, 0.8)",
  SHADOW_GREEN_NEON_SECOND: "rgba(112, 235, 183, 0.3)",
  SHADOW_PURPLE_NEON: "rgba(164,64,242, 0.8)",
  SHADOW_PURPLE_NEON_SECONDARY: "rgba(164,64,242, 0.3)",
  SHADOW_ORANGE_NEON: "rgba(242,128,64, 0.8)",
  SHADOW_ORANGE_NEON_SECOND: "rgba(242,128,64, 0.3)",
  SHADOW_NAVBAR_BUTTON: "rgba(254, 33, 245, 0.43)",
};
